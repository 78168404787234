/*=========================================================================================
  File Name: modulePaymentRequestActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  storePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests", data.obj, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchPaymentRequestListByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPaymentRequestDetailByPRId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/prId/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests/sendCodeToCustomer", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  verifyCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests/verifyCustomerCode", data)
        .then(res => {
          // Set accessToken and refresh token
          localStorage.setItem(
            "accessToken",
            res.data.data.token.accessToken
          );
          localStorage.setItem(
            "refreshToken",
            res.data.data.token.refreshToken
          );
          localStorage.setItem(
            "tokenExpiry",
            res.data.data.token.accessTokenExpiresIn
          );
          localStorage.setItem("loggedIn", "true");
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomerPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequests/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomerPaymentRequestDetailWithPartnerById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequestDetails/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeFileFromPaymentRequestByIdAndFileId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `paymentRequests/${data.id}/file/${data.fileId}?fileName=${data.fileName}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  cancelPaymentRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put("paymentRequests/cancel/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resendPaymentRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put("paymentRequests/resend/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchAmountOrValueByStatusAndDate({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/transcByCard" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchSuccessRateByDate({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/getSuccessRate?date=" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  makePayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/paymentRequests/make-payment", payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          return reject(ex);
        });
    });
  },

  saveProductFieldInPr({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/paymentRequests/saveProductFieldInPr", payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveCustomFieldInPr({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/paymentRequests/saveCustomFieldInPr", payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getQuotationDataForPayLater({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/calculation", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
};
